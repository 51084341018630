import React from "react";
import { ImageBackground } from "react-native";
import { useImage } from "../../../ui/ImageContext";
import { useIsTablet } from "../../../ui/hooks/useIsTablet";

export const GameBackground = ({ children }) => {
  const isTablet = useIsTablet();
  const background = useImage(
    isTablet ? "backgroundTablet" : "backgroundPhone"
  );
  return (
    <ImageBackground
      source={background}
      resizeMethod="resize"
      style={{ flex: 1 }}
    >
      {children}
    </ImageBackground>
  );
};
