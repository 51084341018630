import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import BellCrossIcon from "../../../../ui/icons/BellCross";
import BellIcon from "../../../../ui/icons/Bell";
import Sound from "../../../../ui/icons/Sound";
import SoundCross from "../../../../ui/icons/SoundCross";
import { Show, useSubjectValue } from "react-mlyn";
import { dark, light } from "../../../../ui/theme";
import IconButton from "../../../../ui/elements/IconButton";
import { useNotification } from "../../../../data/notifications/service";

const LanguageSelector = ({ selectedLanguage$ }) => {
  const currentLanguage = useSubjectValue(selectedLanguage$);
  const isLanguageSelected = (language: string) => currentLanguage === language;

  return (
    <View style={styles.languageSelector}>
      {["by", "ru", "en"].map((language) => (
        <TouchableOpacity
          style={styles.languageButton}
          key={language}
          onPress={() => selectedLanguage$(language)}
        >
          <Text
            style={[
              styles.languageText,
              isLanguageSelected(language) && styles.selectedLanguage,
            ]}
          >
            {language.toUpperCase()}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const toggle = (s$) => () => s$(!s$());
const not = (s$) => () => !s$();

export const MenuComponent = (props) => {
  const { settings$, language$, opened } = props;
  const language = useSubjectValue(language$);
  const selectedTheme = useSubjectValue(settings$.theme);
  return (
    <View style={styles.background}>
      <TouchableWithoutFeedback onPress={() => opened(false)}>
        <View style={StyleSheet.absoluteFill} />
      </TouchableWithoutFeedback>
      <View style={styles.container}>
        <View style={styles.row}>
          <IconButton onPress={toggle(settings$.notification)}>
            <Show when={settings$.notification}>{() => <BellIcon />}</Show>
            <Show when={not(settings$.notification)}>
              {() => <BellCrossIcon />}
            </Show>
            <Text style={styles.iconText}>{language.notifications}</Text>
          </IconButton>
        </View>
        <View style={styles.row}>
          <IconButton onPress={toggle(settings$.soundOn)}>
            <Show when={settings$.soundOn}>{() => <Sound />}</Show>
            <Show when={not(settings$.soundOn)}>{() => <SoundCross />}</Show>
            <Text style={styles.iconText}>{language.sound}</Text>
          </IconButton>
        </View>
        <View style={styles.row}>
          <IconButton onPress={toggle(settings$.musicOn)}>
            <Show when={settings$.musicOn}>{() => <Sound />}</Show>
            <Show when={not(settings$.musicOn)}>{() => <SoundCross />}</Show>
            <Text style={styles.iconText}>{language.music}</Text>
          </IconButton>
        </View>
        <View style={[styles.row, { paddingVertical: 30 }]}>
          <IconButton
            onPress={() => {
              settings$.theme("dark");
            }}
          >
            <View
              style={[
                styles.square,
                selectedTheme === "dark" && styles.squareSelected,
                { backgroundColor: "#1E1E1E" },
              ]}
            />
          </IconButton>
          <View style={{ width: 16 }} />
          <IconButton
            onPress={() => {
              settings$.theme("light");
            }}
          >
            <View
              style={[
                styles.square,
                selectedTheme === "light" && styles.squareSelected,
                { backgroundColor: "white" },
              ]}
            />
          </IconButton>
        </View>
        <Text style={styles.text}>{language.language}</Text>
        <LanguageSelector selectedLanguage$={settings$.language} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0,0,0,0.3)",
    alignItems: "center",
    justifyContent: "space-around",
  },
  container: {
    width: 280,
    height: 350,
    backgroundColor: dark.header,
    padding: 30,
    borderRadius: 8,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12, // Adjust the space as needed
  },
  text: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: 26,
    color: "white",
  },
  iconText: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: 26,
    color: "white",
    marginLeft: 8, // Space between icon and text
  },
  icon: {
    // Placeholder style, replace with actual icons
    width: 24,
    height: 24,
    backgroundColor: "white",
  },
  languageSelector: {
    padding: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  languageButton: {
    marginRight: 16,
  },
  selectedLanguage: {
    color: light.connect,
  },
  languageText: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: 26,
    color: "white",
    paddingVertical: 6,
    marginRight: 0, // Space around the text
  },
  square: {
    width: 30,
    height: 30,
    borderRadius: 2,
  },
  squareSelected: {
    borderColor: light.connect,
    borderWidth: 2,
  },
  circle: {
    width: 20,
    height: 20,
    backgroundColor: "white",
    borderRadius: 10,
    marginRight: 8,
  },
});
