import styled from "styled-components/native";
import { colors } from "../../../../ui/theme";

export const Chat = styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    paddingLeft: 13,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 40,
  },
}))`
  flex: 1;
  /* background-color: ${colors.chat}; */
`;
