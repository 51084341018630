import React from 'react';
import Svg, { Path } from 'react-native-svg';

const Hamburger = () => {
  return (
    <Svg width="46" height="46" viewBox="0 0 46 46" fill="none">
      <Path d="M14 16H32" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <Path d="M14 23H32" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <Path d="M14 30H32" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </Svg>
  );
};

export default Hamburger;
