import React, { useContext, useEffect } from "react";
import { useHandler } from "react-use-handler";
import { mergeWith, isEqual } from "lodash";
import { Show, useMlynEffect, useSubject } from "react-mlyn";
import * as Styles from "./Footer.styles";
import { ActionsView } from "./components/ActionsView";

import { FatalStepView } from "./components/FatalStepView";
import { ButtonView } from "./components/ButtonView";
import { ProtagonistIsAway } from "./components/ProtagonistIsAway";
import { PropmtView } from "./components/PropmtView";
import { Subject } from "mlyn";
import { Action, Step } from "../../../../data/domain";
import { ProtagonistIsWriting } from "./components/ProtagonistIsWriting";
import { Alert } from "react-native";
import { paymentService } from "../../../service/paymentService";

export const Footer = React.memo(
  ({
    state,
    skipTimeout,
    selectedLanugage$,
    language$,
    loading,
    expanded,
    promptExpanded,
    currentStep,
    awayReason,
    passStep,
    waitingUntil,
    backToSafe,
    restart,
  }) => {
    const openMessages = useHandler(() => {
      expanded(true);
    });

    useMlynEffect(() => {
      if (currentStep.isQuestion()) {
        promptExpanded(true);
      }
    });

    const answerQuestion = (answer: string) => {
      const step = currentStep as Subject<Step>;
      promptExpanded(false);
      if (!step.correctAnswer() || step.correctAnswer() === answer.trim()) {
        sendMessage(step.actions[0](), answer);
      } else {
        sendMessage(step.actions[1](), answer);
      }
    };

    const sendMessage = useHandler((action: Action, meta: any) => {
      expanded(false);
      passStep(action, meta);
    });
    const isStepFatal = () => currentStep() && currentStep().fatal;
    const isStepVictory = () => currentStep() && currentStep().victory;
    const canSendMessage = () =>
      !loading() && currentStep() && !currentStep.autoswitch();
    const canOpenMessages = () =>
      !isStepVictory() &&
      !isStepFatal() &&
      !waitingUntil() &&
      !currentStep.isQuestion();
    const messagesAreOpened = () => expanded() && !currentStep.autoswitch();
    // const fakeTimeout = useSubject(+(new Date()) + 5000000);
    // return (
    //   <ProtagonistIsAway language$={language$} timeout={fakeTimeout} />
    // );
    let lastValue = state.__value;
    useMlynEffect(() => {
      if (isStepVictory() && !paymentService.isPayingUser()) {
        Alert.alert(language$().victory, language$().donateVictory, [
          {
            text: language$().donateVictoryYes,
            onPress: paymentService.initiatePayment,
          },
          { text: language$().donateVictoryNo },
        ]);
      }
    });
    useMlynEffect(() => {
      // console.log(">>> loading: ", loading());
      // if (!loading()) {
      //   console.log("\n\n\n\n\n ================== START ==================")
      //   mergeWith(
      //     state.__value,
      //     lastValue,
      //     function (objectValue, sourceValue, key, object, source) {
      //       if (
      //         !isEqual(objectValue, sourceValue) &&
      //         Object(objectValue) !== objectValue
      //       ) {
      //         console.log(
      //           key +
      //             "\n    Expected: " +
      //             sourceValue +
      //             "\n    Actual: " +
      //             objectValue
      //         );
      //       }
      //     }
      //   );
      //   lastValue = state.__value;
      // }
      // console.log(">>> waitingUntil: ", waitingUntil());
    });
    return (
      <Styles.Container>
        <Show when={waitingUntil}>
          {() => {
            return (
              <ProtagonistIsAway
                awayReason={awayReason}
                language$={language$}
                timeout={waitingUntil}
                onSkip={skipTimeout}
              />
            );
          }}
        </Show>
        <Show when={() => promptExpanded() && !loading()}>
          {() => (
            <PropmtView language$={language$} answerQuestion={answerQuestion} />
          )}
        </Show>
        <Show when={() => canOpenMessages() && canSendMessage() && !messagesAreOpened()}>
          {() => (
            <ButtonView
              language$={language$}
              openMessages={openMessages}
              canSendMessage={canSendMessage}
            />
          )}
        </Show>
        <Show when={() => loading() && !waitingUntil()}>
          {() => {
            return <ProtagonistIsWriting language$={language$} />;
          }}
        </Show>
        <Show when={messagesAreOpened}>
          {() => {
            return (
              <ActionsView
                selectedLanugage$={selectedLanugage$}
                currentStep$={currentStep}
                sendMessage={sendMessage}
              />
            );
          }}
        </Show>
      </Styles.Container>
    );
  }
);
