import { Sound } from "expo-av/build/Audio";
import React from "react";
import { seal } from "react-mlyn";
import { Audio } from "expo-av";
import { Subject } from "mlyn";
import { Settings } from "../../../data/domain";
import { ClickSound } from "../../../../Assets";

let clickSound: Sound;
(async () => {
  const { sound } = await Audio.Sound.createAsync(ClickSound);
  clickSound = sound;
})();

export const SoundContext = React.createContext(undefined);

interface Props {
  children: React.ReactElement;
  settings$: Subject<Settings>;
}

export const SoundProvider = seal(({ children, settings$ }: Props) => {
  return (
    <SoundContext.Provider
      value={{
        playClick: () => {
          if (settings$.soundOn.__value) {
            try {
              clickSound.playFromPositionAsync(0);
            } catch (err) {
              console.log(">>> err: ", err);
            }
          }
        },
      }}
    >
      {children}
    </SoundContext.Provider>
  );
});
