import { useEffect, useState } from "react";
import DeviceInfo from "react-native-device-info";

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    (async () => {
      const isTabletDevice = await DeviceInfo.isTablet();
      setIsTablet(isTabletDevice);
    })();
  }, []);
  return isTablet;
};
