import { Subject } from "mlyn";
import React from "react";
import { Show, useSubjectValue } from "react-mlyn";
import { Platform } from "react-native";
import { useHandler } from "react-use-handler";
import { Language, Step } from "../../../../../../data/domain";
import * as Styles from "./StepView.styles";
import VideoPlayer from "../VideoPlayer";
import { HistoryItem } from "../../../../hooks/useHistory";

interface Props {
  history: Subject<HistoryItem[]>;
  step$: Subject<Step>;
  steps$: Subject<Step[]>;
  orderedSteps$: Subject<Step[]>;
  selectedLanguage$: Subject<Language>;
  index: number;
  revertTo(stepId: string): void;
}

export const StepView = React.memo((props: Props) => {
  const { history, step$, steps$, index, revertTo, selectedLanguage$ } = props;
  const stepId = useSubjectValue(step$.id);
  const revertToStep = useHandler(() => {
    revertTo(stepId);
  });
  const isFirstVideo = () => {
    const stepIds = history();
    const stepsValue = steps$() as Step[];
    for (let i = 0; i < index; i++) {
      const stepItem = stepsValue.find(({ id }) => id === stepIds[i].stepId);
      if (stepItem!.type === "video") {
        return false;
      }
    }
    return true;
  }
  return (
    <React.Fragment key={`${stepId}-${index}`}>
      <Show when={() => step$.type() === "image"}>
        {() => <Styles.SentImage source={{ uri: step$.image["by"]() }} />}
      </Show>
      <Show when={() => step$.type() === "text"}>
        {() => (
          <Styles.IncomingMessage style={{ borderBottomLeftRadius: 0 }}>
            <Styles.MessageText>
              {() => step$.title[selectedLanguage$()]()}
            </Styles.MessageText>
          </Styles.IncomingMessage>
        )}
      </Show>
      <Show when={() => step$.type() === "video"}>
        {() => (
          <VideoPlayer
            isFirstVideo={isFirstVideo}
            videoUri$={step$.video["by"]}
            posterUri$={step$.image["by"]}
          />
        )}
      </Show>
      <Show when={() => history[index].selectedAction()}>
        {() => {
          const action = step$().actions.find(
            (a) => a.id === history[index].selectedAction()
          );
          return (
            <Styles.OutgoingMessage
              style={{ borderBottomRightRadius: 0 }}
              disabled={Platform.OS !== "web"}
              onPress={revertToStep}
            >
              <Styles.OutgoingMessageText>
                {() =>
                  step$.isQuestion()
                    ? history[index].meta()
                    : action!.title[selectedLanguage$()]
                }
              </Styles.OutgoingMessageText>
            </Styles.OutgoingMessage>
          );
        }}
      </Show>
    </React.Fragment>
  );
});
