import styled, { css } from "styled-components/native";
import { userSelect } from "../../../../../../ui/common.styles";
import { Mlyn } from "../../../../../../ui/Mlyn";
import { colors } from "../../../../../../ui/theme";
import { Dimensions } from "react-native";

export const MessageCss = css`
  padding: 14px;
  ${userSelect};
  overflow: visible;
`;

export const Label = styled(Mlyn.Text)`
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: white;
  font-size: 16;
`;

export const DividerImage = styled.Image`
  width: ${Dimensions.get("window").width};
  height: 8px;
  position: absolute;
  top: -3px;
  left: 0px;
  right: 0px;
`;

export const Action = styled.TouchableOpacity`
  /* border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.header}; */
  /* background-color: ${({ pressed }) => pressed ? colors.divider : colors.header}; */
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  /* ${MessageCss} */
`;

export const ActionsContainer = styled.View`
  background-color: ${colors.header};
  width: 100%;
  padding-bottom: 12px;
`;
