import React, { useContext, useState, useRef, useMemo } from "react";
import { createSubject, runInReactiveScope } from "mlyn";
import { useMlynEffect, useSubject, useSubjectValue } from "react-mlyn";
import { useHandler } from "react-use-handler";

const ImageContext = React.createContext({});

const darkImages = {
    backgroundPhone: require("../../assets/ui/dark/dark_background_phone.png"),
    backgroundTablet: require("../../assets/ui/dark/dark_background_tablet.png"),
    frame: require("../../assets/frame-dark.png"),
    divider: require("../../assets/divider-dark.png"),
    phone: require("../../assets/phone-dark.png"),
};
const lightImages = {
    backgroundPhone: require("../../assets/ui/light/background_phone.png"),
    backgroundTablet: require("../../assets/ui/light/background_tablet.png"),
    frame: require("../../assets/frame.png"),
    divider: require("../../assets/divider.png"),
    phone: require("../../assets/phone.png"),
};  

export const ImageProvider = React.memo(({ children, theme }) => {
    const images = useSubject(lightImages);
    useMlynEffect(() => {
        console.log(">>> theme():", theme());
        const newImages = theme() === "light" ? lightImages : darkImages;
        images(newImages);
    });
    return (
        <ImageContext.Provider value={{ images }}>
            {children}
        </ImageContext.Provider>
    );
});

export const useImage = (imageName: string) => {
    const { images } = useContext(ImageContext);
    const activeImages = useSubjectValue(images);
    console.log(">>> activeImages:", activeImages);
    return activeImages[imageName];
};

export const useImage$ = (imageName: string) => {
    const { images } = useContext(ImageContext);
    return images[imageName];
};
