import React, { useContext } from "react";
import * as Styles from "../Footer.styles";
import { SoundContext } from "../../../contexts/SoundContext";
import { Cursor } from "./cursor";

interface Props {
  language$: any;
  canSendMessage(): boolean;
  openMessages(): void;
}

export const ButtonView = (props: Props) => {
  const { language$, canSendMessage, openMessages } = props;
  const { playClick } = useContext(SoundContext);
  return (
    <Styles.Footer>
      <Styles.FooterButton
        activeOpacity={0.8}
        onPress={() => {
          if (canSendMessage()) {
            playClick();
            openMessages();
          }
        }}
      >
        <Styles.InputFrame>
          {/* <Cursor /> */}
          <Styles.InputText>{language$.writeMessage}</Styles.InputText>
        </Styles.InputFrame>
      </Styles.FooterButton>
    </Styles.Footer>
  );
};
