import React, { useEffect } from "react";
import * as Styles from "../Footer.styles";
import {
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
} from "date-fns";
import { Subject } from "mlyn";
import { useSubject, useSubjectValue } from "react-mlyn";
import { Button, TouchableOpacity } from "react-native";

// http://localhost:19006/?file=default&h2=WyJvd2trYWF4ZSIsIm9raXV5c3YiLCJzcHNodWRhYSIsInltZGFxZGhpIiwiZGl4c2dncmsiLCJodW9pb25nIiwiZndjanB0IiwieG9iZHd5eXEiLCJib2lkb3VxZiIsImpzdGlrbGFiIiwia2hoemRkdyIsInNraHl0bHIiLCJieGRwY21pIiwiam9wZnpjY28iLCJhcmZqY25vbiIsImljYXZnYWRiIiwidm56ZXdpYm8iLCJqdHZ5ZW91biIsImRma3NmdyIsImhuaGxubW0iLCJ6eWJ2c2EiLCJjam1ub3F1dyIsInVjZWl4eGZsIiwieWZicnN4bWoiLCJ3eGdybnh4cSIsInRuaHN6enMiLCJ4ZmZnb2ZxaSIsInRqZnllbnB3IiwiYmhsdWhtIiwieWVjY3l1ZCIsInhraWZrbGd6IiwiY2t4amdncyIsIm5ncWZhaGVwIiwianR2enFkaXYiLCJqd2JjdHF0IiwieXRoZm9pdm0iLCJ1aGV6Zm9sbSIsImNlenphcm8iLCJsYWdrcWhrZiIsInh1dnF6enFnIiwibmRrZXVvZHYiLCJwbXppb2N5ciIsImhmYnRzdnh6IiwidXlrY3Vzd2kiLCJxcGR3aGtuIiwibHp0aiIsIm1hZndlZ2wiLCJmZXlpamxhciIsImx0dWRieHBmIiwibHRocXZ6dnkiLCJqanRqZWFsdSIsImRhZGh1aGtiIiwiZHRsaHUiLCJxbWRlcGtneiIsImx4cnlzZHkiLCJvaGN4dnQiLCJ3dXVwZ3JkZSIsImdydmJhbCIsInBmbmtmcG1iIiwienRsdm1iaiIsInBod25nZW9zIiwienhrcmZrcm0iLCJqd2Fkd3ZjIiwibnVscWFnY2EiLCJldXR5d3kiLCJldGZicnByIiwicGVnZ2t3YyIsImN6bmt4dGV6IiwidWF3ZXR2d28iLCJjd2FtcGpzIiwiZmJxZnp5b3QiLCJ0emhndmd4cyIsIm1wZGRqanhuIiwiYndpdGJvdG0iLCJqemdycG9tZyIsInN6ZndkYXZvIiwidXF0aWtkaWgiLCJsYnliZmhrbiIsInNuZHplZXZlIiwiaXFjd3BkbSIsImZydnlncHlrIiwiYXFucW5lYyIsImdycHBvdmh4IiwibWV0dmFsc2kiLCJyYmxyaWtmIiwianp1eGllY2wiLCJmbHBydWN5bCIsInNseHdxeWxtIiwiZWJheXVwbHciLCJwaWRzZGJteiIsImpybm9wY3hiIiwicHRsdXMiLCJtd2tyamMiLCJoZWV2YWJucCIsInJnaWpxampiIiwiamlia3FqbiIsIm5qbnlnZGl5IiwiY2hya2hueCIsIm1hcmlobGV0Iiwid2xneGl2ZGEiLCJnZWNlc2pwIiwibGlqeHVvbG0iLCJjZmZkcXdhZiIsInRoZGxwdCIsIm91bm9nZWJpIiwiemlsYWpmeGQiLCJkZHhzbXZyZCIsInZvamVkIiwidXZqamJ0aGwiLCJjb2Vqd3llZyIsImVtb3B6IiwidHdybXpociIsImlqeWZlZ2lvIiwidWtlaG91cXEiLCJvZmtvbCIsImNsYWl4d3EiLCJkZHB6dHJ6bSIsInB4bWN2Ymd1IiwicmR5cmdoZHYiLCJkdmx4bHlwIiwiaXBwdmJjaXAiLCJzaGpkd3l0IiwidGx0d21kb2siLCJsdnJweGMiLCJramd2dXFuaiIsImV3bmloZnFsIiwiaHBzb3R5YW0iLCJka3hrcnRodSIsImxvZG5rcnZrIiwieXl2bmRwIiwiZWhqd21yb3YiLCJiemlyZXVsYyIsImFjYnQiLCJld2NwdGR5cSIsIm9waXppdWUiLCJ5dHd2YXpjbiIsImZ5cHhqaXFxIiwiZmhzZ2EiLCJ2ZGNrYiM5Iiwiem9sbmFzamojNyJd

const f = (s: string) => `${"0".repeat(Math.max(2 - s.length, 0))}${s}`;

const formatTime = (timeRemaining: number) => {
  const h = differenceInHours(timeRemaining, 0).toString();
  const m = (differenceInMinutes(timeRemaining, 0) % 60).toString();
  const s = (differenceInSeconds(timeRemaining, 0) % 60).toString();
  return `${f(h)}:${f(m)}:${f(s)}`;
};

const useTimeRemaining = (timeout$: Subject<number>) => {
  // console.log(">>> t1:", timeout$());
  const timeRemaining$ = useSubject(
    differenceInMilliseconds(parseISO(timeout$()), new Date())
  );
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log(">>> timeout$():", timeout$());
      // console.log(">>> new Date():", new Date());
      // console.log(
      //   ">>> tr:",
      //   differenceInMilliseconds(parseISO(timeout$()), new Date())
      // );
      // console.log(">>> t2:", parseISO(timeout$()));
      timeRemaining$(
        differenceInMilliseconds(parseISO(timeout$()), new Date())
      );
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return timeRemaining$;
};

interface Props {
  timeout: Subject<number>;
  onSkip(): void;
  language$: any;
}

export const ProtagonistIsAway = React.memo((props: Props) => {
  const { timeout, language$, onSkip, awayReason } = props;
  const language = useSubjectValue(language$);
  const timeRemaining$ = useTimeRemaining(timeout);
  return (
    <Styles.FooterTransparent>
      <Styles.TransparentContainer>
        <Styles.LabelAway>
          {() => {
            // return "213r";
            return `${awayReason() || language.martinIsAway}
(${formatTime(timeRemaining$())})`;
          }}
        </Styles.LabelAway>
      </Styles.TransparentContainer>
      <Styles.Background>
        <TouchableOpacity
          onPress={onSkip}
          style={{ flex: 1 }}
          activeOpacity={0.7}
        >
          <Styles.InputFrame style={{ justifyContent: "space-around" }}>
            <Styles.Label>{language.skip}</Styles.Label>
          </Styles.InputFrame>
        </TouchableOpacity>
      </Styles.Background>
    </Styles.FooterTransparent>
  );
});
