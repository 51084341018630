import React from 'react';
import Svg, { Path } from 'react-native-svg';

const SkullIcon = () => (
  <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M18.1248 25.9587C19.2064 25.9587 20.0832 25.0819 20.0832 24.0003C20.0832 22.9188 19.2064 22.042 18.1248 22.042C17.0433 22.042 16.1665 22.9188 16.1665 24.0003C16.1665 25.0819 17.0433 25.9587 18.1248 25.9587Z" fill="#5B5B5B" stroke="#5B5B5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M29.8748 25.9587C30.9564 25.9587 31.8332 25.0819 31.8332 24.0003C31.8332 22.9188 30.9564 22.042 29.8748 22.042C28.7933 22.042 27.9165 22.9188 27.9165 24.0003C27.9165 25.0819 28.7933 25.9587 29.8748 25.9587Z" fill="#5B5B5B" stroke="#5B5B5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M16.1665 39.667V43.5837H31.8332V39.667" stroke="#5B5B5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M24.9793 33.7913L24.0002 31.833L23.021 33.7913H24.9793Z" stroke="#5B5B5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M31.8335 39.6668C32.5712 39.6664 33.2938 39.4576 33.918 39.0645C34.5422 38.6714 35.0428 38.1101 35.362 37.445C35.6812 36.78 35.8062 36.0383 35.7224 35.3054C35.6386 34.5725 35.3496 33.8781 34.8885 33.3022C37.1327 31.133 38.6778 28.3428 39.3256 25.2896C39.9735 22.2363 39.6946 19.0592 38.5247 16.1655C37.3547 13.2718 35.3471 10.7935 32.7593 9.04846C30.1715 7.3034 27.1214 6.37109 24.0002 6.37109C20.8789 6.37109 17.8288 7.3034 15.241 9.04846C12.6532 10.7935 10.6456 13.2718 9.47568 16.1655C8.30576 19.0592 8.02684 22.2363 8.6747 25.2896C9.32255 28.3428 10.8676 31.133 13.1118 33.3022C12.6508 33.8781 12.3617 34.5725 12.278 35.3054C12.1942 36.0383 12.3191 36.78 12.6383 37.445C12.9575 38.1101 13.4581 38.6714 14.0823 39.0645C14.7066 39.4576 15.4291 39.6664 16.1668 39.6668" stroke="#5B5B5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

export default SkullIcon;
