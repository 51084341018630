import AsyncStorage from "@react-native-async-storage/async-storage";
import { Subject } from "mlyn";
import { useMlynEffect } from "react-mlyn";
import { Platform } from "react-native";
import { isPast } from "date-fns";

// import AsyncStorage from '@react-native-async-storage/async-storage';

export interface HistoryItem {
  stepId: string;
  selectedAction: string | undefined;
  meta: any;
}

export const intitialHistory: HistoryItem[] = [];

const historyLabel = "h2";

export const getStep = (variables, steps, stepId) => {
  const variable = variables.find(({ id }) => id === stepId);
  if (variable) {
    const nextStep = steps.find(({ id }) => id === variable.stepId);
    return nextStep;
  } else {
    return steps.find(({ id }) => id === stepId);
  }
};

const historyServiceWeb = {
  saveHistory: (value: string) => {
    try {
      const history64 = btoa(value);
      const urlParams = new URLSearchParams(window.location.search);
      const fileName = urlParams.get("file") || "default";
      window.history.pushState(
        { page: 1 },
        "sot",
        `${window.location.origin}?file=${fileName}&${historyLabel}=${history64}`
      );
    } catch (err) {
      console.log("could not update url: ", err);
    }
  },
  getHistory: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const historyParam = urlParams.get(historyLabel);
    if (historyParam) {
      return atob(historyParam);
    }
    return undefined;
  },
  saveSheduled: () => {},
  getSheduled: () => {},
};

const historyServiceMobile = {
  saveHistory: async (value: string) => {
    await AsyncStorage.setItem("history", value);
  },
  getHistory: async () => {
    return AsyncStorage.getItem("history");
    // return null;
  },
  saveSheduled: async (
    scheduled: { stepId: string; timeout: string } | null
  ) => {
    await AsyncStorage.setItem(
      "scheduled",
      scheduled ? JSON.stringify(scheduled) : ""
    );
  },
  getSheduled: async () => {
    const data = await AsyncStorage.getItem("scheduled");
    return data ? JSON.parse(data) : data;
    // return null;
  },
};

export const historyService =
  Platform.OS === "web" ? historyServiceWeb : historyServiceMobile;

export const isStepScheduled = async () => {
  const scheduled = await historyService.getSheduled();
  return scheduled && !isPast(new Date(scheduled.timeout));
};

export const useHistory = (history: Subject<HistoryItem[]>) => {
  useMlynEffect(() => {
    if (history() !== intitialHistory) {
      const historyHash = JSON.stringify(
        history()
          .map(({ selectedAction, meta }) =>
            meta ? `${selectedAction}#${meta}` : selectedAction
          )
          .filter(Boolean)
      );
      historyService.saveHistory(historyHash);
    }
  });
  // const getHistoryFromLocalStorage = async () => {
  //     const preloadedHistory = await AsyncStorage.getItem(historyLabel);
  //     if (preloadedHistory) {
  //         history([JSON.parse(preloadedHistory).lastStep]);
  //     }
  // }
  const preloadHistory = async (variables, steps: Step) => {
    try {
      const historyDecoded = await historyService.getHistory();
      if (historyDecoded) {
        const preloadedHistory = JSON.parse(historyDecoded);
        if (preloadedHistory) {
          let currentStep = steps[0];
          const reconstructedHistory: HistoryItem[] = [];
          for (let i = 0; i < preloadedHistory.length; i++) {
            const [nextAction, meta] = preloadedHistory[i].split("#");
            reconstructedHistory.push({
              stepId: currentStep.id,
              selectedAction: nextAction,
              meta,
            });
            const selectedAction = currentStep.actions.find(
              ({ id }) => id === nextAction
            );

            for (const assignment of selectedAction.assignments) {
              const varIndex = variables().findIndex(
                ({ id }) => id === assignment.variableId
              );
              variables[varIndex].stepId(assignment.stepId);
            }
            currentStep = getStep(variables(), steps, selectedAction.nextId);
            if (currentStep.autoswitch) {
              while (
                currentStep.autoswitch &&
                !currentStep.victory &&
                !currentStep.fatal
              ) {
                reconstructedHistory.push({ stepId: currentStep.id });
                currentStep.autoNextStep;
                currentStep = getStep(
                  variables(),
                  steps,
                  currentStep.autoNextStep
                );
              }
            }
          }
          // comment for debug
          if (!(await isStepScheduled())) {
            reconstructedHistory.push({ stepId: currentStep.id });
          }
          history(reconstructedHistory);
        }
      }
    } catch (err) {
      console.log("could not load history from url: ", err);
      // getHistoryFromLocalStorage();
    }
  };
  return {
    preloadHistory,
  };
};
