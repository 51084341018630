import { createSelector, Subject } from "mlyn";
import React from "react";
import {
  useSubject,
  useMlynEffect,
  useMemoize,
  useSubjectValue,
} from "react-mlyn";
import * as Styles from "./Chat.styles";
import { StepView } from "./components/StepView";
import { Step } from "../../../../data/domain";
import { useImage } from "../../../../ui/ImageContext";
import { ImageBackground } from "react-native";
import { DeviceInfo } from "react-native-device-info"
import { useIsTablet } from "../../../../ui/hooks/useIsTablet";
// const idsSelector = createSelector(
//   (array) => {;
//     return array().map(({ stepId }) => stepId)
//   },
//   compareArrays
// );

interface Props {
  history: any;
  steps: Subject<any[]>;
  expanded: Subject<boolean>;
  language: Subject<string>;
  selectedLanguage$: Subject<string>;
  revertTo(): void;
}

export const Chat = React.memo((props: Props) => {
  const { history, steps, expanded, loading, language, revertTo, selectedLanguage$ } =
    props;
  // @ts-ignore
  const scrollRef = useSubject<React.ReactHTMLElement>();
  const stepIdsSubject = useMemoize(() =>
    history().map(({ stepId }) => stepId)
  );
  const stepIds = useSubjectValue(stepIdsSubject);
  useMlynEffect(() => {
    history();
    loading();
    requestAnimationFrame(() => {
      scrollRef().scrollToEnd();
    });
    setTimeout(() => {
      scrollRef().scrollToEnd();
    }, 300);
  });
  useMlynEffect(() => {
    if (expanded()) {
      requestAnimationFrame(() => {
        scrollRef().scrollToEnd();
      });
    }
  });
  const orderedSteps = (
    stepIds.map(
      (stepId: string) => steps[steps().findIndex(({ id }) => id === stepId)]
    ) as Subject<Step>[]
  ).filter(({ fatal }) => !fatal());
  
  return (
    <Styles.Chat ref={scrollRef}>
      {orderedSteps.map((step$, index) => {
        return (
          <StepView
            steps$={steps}
            key={step$.id()}
            revertTo={revertTo}
            history={history}
            language$={language}
            selectedLanguage$={selectedLanguage$}
            step$={step$}
            index={index}
          />
        );
      })}
    </Styles.Chat>
  );
});
