import { useContext } from "react";
import { useSubjectValue } from "react-mlyn";
import { ThemeContext } from "styled-components/native";

type Theme = {
  header: string;
  icons: string;
  divider: string;
  chat: string;
  text: string;
  themeBorder: string;
  incomingMessage: string;
  outgoingMessage: string;
  input: string;
  connect: string;
  noConnection: string;
  incomingMessageText: string;
  outgoingMessageText: string;
};

export const light: Theme = {
  header: "#343434",
  icons: "#9a897d",
  divider: "#898c96",
  text: "black",
  chat: "#fefefe",
  themeBorder: "#877468",
  incomingMessage: "#D1C5AF",
  outgoingMessage: "#335045",
  incomingMessageText: "#1E1E1E",
  outgoingMessageText: "#FFFFFF",
  input: "#686c74",
  connect: "#57AA69",
  noConnection: "#BF4957",
};

export const dark: Theme = {
  header: "#343434",
  icons: "#0b000b",
  divider: "#595b62",
  text: "white",
  chat: "#332c2c",
  themeBorder: "#5d3c38",
  incomingMessage: "#D1C5AF",
  outgoingMessage: "#335045",
  incomingMessageText: "#1E1E1E",
  outgoingMessageText: "#FFFFFF",
  input: "#cac4c0",
  connect: "#57AA69",
  noConnection: "#BF4957",
};

type Colors = {
  [key in keyof Theme]: (props: { theme: Record<string> }) => Theme[key];
};

export const colors: Colors = Object.keys(dark).reduce((composition, key) => {
  return {
    ...composition,
    [key]: ({ theme }) => theme[key],
  };
}, {} as Partial<Colors>) as Colors;

export const useColor = (selector: () => Colors[keyof Colors]) => {
  const theme = useContext(ThemeContext);
  return () => {
    return selector()({ theme });
  };
};


export const useColorValue = (selector: () => Colors[keyof Colors]) => {
  const color$ = useColor(selector);
  return useSubjectValue(color$);
};

export const theme = light;
