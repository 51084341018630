import { createSubject } from "mlyn";
import { Language } from "../data/domain";

const by = {
  userName: "Марцін",
  online: "У сетцы",
  offline: "Не ў сетцы",
  noConnection: "Няма сувязі...",
  writeMessage: "Напішыце паведамленне.",
  martinIsAway: "Марцін адыйшоў...",
  skip: "Прапусціць",
  martinIsWriting: "Марцін піша...",
  theme: "Тэма",
  language: "Мова",
  startAgain: "Пачаць спачатку",
  victory: "Віншуем, Вы перамаглі!",
  donateVictory: "Падтрымайце стваральнікаў гульні!",
  donateVictoryYes: "Падтрымаць",
  donateVictoryNo: "Не",
  playAgain: "Пачаць зноў.",
  martinDies: "Марцін памірае...",
  enterAnswer: "Увядзіце адказ:",
  submit: "Адправіць",
  gackToSafePoint: "Вярнуцца ў бяспечную кропку.",
  notifications: "Апавяшчэнні",
  sound: "Гук",
  music: "Музыка",
};

export const localizations$ = createSubject<Record<Language, typeof by>>({
  by,
  ru: {
    userName: "Мартин",
    online: "В сети",
    offline: "Не в сети",
    noConnection: "Нет связи...",
    writeMessage: "Напишите сообщение.",
    martinIsAway: "Мартин отошел...",
    skip: "Пропустить",
    martinIsWriting: "Мартин пишет...",
    theme: "Тема",
    language: "Язык",
    startAgain: "Начать сначала",
    victory: "Поздравляем, вы победили!",
    donateVictory: "Поддержите создателей игры!",
    donateVictoryYes: "Поддержать",
    donateVictoryNo: "Нет",
    playAgain: "Начать заново.",
    martinDies: "Мартин умирает...",
    enterAnswer: "Введите ответ:",
    submit: "Отправить",
    gackToSafePoint: "Вернуться в последнюю безопасную точку.",
    notifications: "Нотификации",
    sound: "Звук",
    music: "Музыка",
  },
  en: {
    userName: "Martin",
    online: "Online",
    offline: "Offline",
    noConnection: "No connection...",
    writeMessage: "Write message.",
    martinIsAway: "Martin is away...",
    skip: "Skip",
    martinIsWriting: "Martin is writing...",
    theme: "Theme",
    language: "Language",
    startAgain: "Start from beggining",
    victory: "Congratulations, you won!",
    donateVictory: "Please, support creators of the game!",
    donateVictoryYes: "Yes",
    donateVictoryNo: "No",
    playAgain: "Start again.",
    martinDies: "Martin dies...",
    enterAnswer: "Enter answer:",
    submit: "Submit:",
    gackToSafePoint: "Back to last safe point.",
    notifications: "Notifications",
    sound: "Sound",
    music: "Music",
  },
});
