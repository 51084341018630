import styled, { css } from "styled-components/native";
import { colors } from "../../../../../../ui/theme";
import { Animated } from "react-native";

export const Cursor = styled(Animated.View)`
  width: 1px;
  height: 20px;
  background-color: white;
  margin-right: 8px;
`;
