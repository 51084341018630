import styled, { css } from 'styled-components/native';
import { Mlyn } from '../../../../../../ui/Mlyn';
import { colors } from "../../../../../../ui/theme";

export const Message = css`
  margin-top: 8px;
  border-radius: 24px;
  max-width: 271px;
`;

const IncommingMessagePadding = css`
  padding-right: 16px;
  padding-left: 23px;
  padding-top: 10px;
  padding-bottom: 12px;
`;

export const IncomingMessage = styled.View<{ noPadding?: boolean }>`
  ${({ noPadding }) => !noPadding && IncommingMessagePadding}
  ${Message}
  align-self: flex-start;
  background-color: ${colors.incomingMessage};
`;

// export const OutgoingMessage = styled.View`
export const OutgoingMessage = styled.TouchableOpacity`
  ${Message}
  padding-horizontal: 16px;
  padding-top: 7px;
  padding-bottom: 10px;
  align-self: flex-end;
  background-color: ${colors.outgoingMessage};
`;

export const MessageText = styled(Mlyn.Text)`
  letter-spacing: 0;
  font-size: 18px;
  color: ${colors.incomingMessageText};
`;

export const OutgoingMessageText = styled(Mlyn.Text)`
  letter-spacing: 0;
  font-size: 18px;
  color: ${colors.outgoingMessageText};
`;


export const SentImage = styled.Image`
  margin-top: 20px;
  border-radius: 24px;
  width: 240px;
  height: 135px;
`;
