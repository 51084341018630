import React from 'react';
import Svg, { Path } from 'react-native-svg';

const TrophyIcon = () => (
  <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M12.2498 18.1247H9.31234C8.01388 18.1247 6.76861 17.6089 5.85046 16.6907C4.93231 15.7726 4.4165 14.5273 4.4165 13.2288C4.4165 11.9304 4.93231 10.6851 5.85046 9.76696C6.76861 8.84882 8.01388 8.33301 9.31234 8.33301H12.2498" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M35.75 18.1247H38.6875C39.986 18.1247 41.2312 17.6089 42.1494 16.6907C43.0675 15.7726 43.5833 14.5273 43.5833 13.2288C43.5833 11.9304 43.0675 10.6851 42.1494 9.76696C41.2312 8.84882 39.986 8.33301 38.6875 8.33301H35.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M8.3335 43.583H39.6668" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M20.0835 29.209V33.7915C20.0835 34.8686 19.1631 35.7106 18.1839 36.1611C15.8731 37.2186 14.2085 40.1365 14.2085 43.5832" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M27.9165 29.209V33.7915C27.9165 34.8686 28.8369 35.7106 29.8161 36.1611C32.1269 37.2186 33.7915 40.1365 33.7915 43.5832" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M35.75 4.41699H12.25V18.1253C12.25 21.2416 13.4879 24.2303 15.6915 26.4338C17.895 28.6374 20.8837 29.8753 24 29.8753C27.1163 29.8753 30.105 28.6374 32.3085 26.4338C34.5121 24.2303 35.75 21.2416 35.75 18.1253V4.41699Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

export default TrophyIcon;
