import React, { useContext } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import { Subject } from "mlyn";
import { Language, Step } from "../../../../../data/domain";
import { SettingsContext } from "../../../contexts/SettingsContext";
import TrophyIcon from "../../../../../ui/icons/TrophyIcon"; // Assuming you have a trophy icon for victory
import { Mlyn } from "../../../../../ui/Mlyn";
import { paymentService } from "../../../../service/paymentService";
interface VictoryProps {
  language$: any;
  restart(): void;
  currentStep$: Subject<Step>;
}

export const VictoryStepView = (props: VictoryProps) => {
  const { language$, restart, currentStep$ } = props;
  const { language } = useContext(SettingsContext);
  const victoryText = currentStep$().title[language() as Language];
  return (
    <Background style={StyleSheet.absoluteFill}>
      <Container>
        <Trophies>
          <TrophyIcon />
          <TrophyIcon />
          <TrophyIcon />
        </Trophies>
        <VictoryText>{victoryText || language$.victory}</VictoryText>
        <Button activeOpacity={0.7} onPress={restart}>
          <ButtonBackground>
            <ButtonLabel>{language$.startAgain}</ButtonLabel>
          </ButtonBackground>
        </Button>
        <Button activeOpacity={0.7} onPress={async () => {
          await paymentService.initiatePayment();
          restart();
        }}>
          <ButtonBackground>
            <ButtonLabel>{language$.donateVictory}</ButtonLabel>
          </ButtonBackground>
        </Button>
      </Container>
    </Background>
  );
};

const VictoryText = styled(Mlyn.Text)`
  margin-top: 15px;
  margin-bottom: 30px;
  font-family: Inter;
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  color: #343434;
  text-align: center;
`;

const Trophies = styled.View`
  align-self: center;
  justify-content: space-between;
  width: 200px;
  flex-direction: row;
`;

const Background = styled.View`
  align-items: center;
  justify-content: space-around;
  background-color: "rgba(0,0,0,0.7)";
`;

const Button = styled.TouchableOpacity`
  margin-bottom: 15px;
`;

const ButtonLabel = styled(Mlyn.Text)`
  color: #343434;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;

const ButtonBackground = styled.View`
  align-items: center;
  justify-content: center;
  width: 362px;
  height: 56px;
  padding: 4px;
  border-radius: 24px;
  background-color: #D0C4AE;
`;

const Container = styled.View`
  width: 410px;
  height: 364px;
  padding: 60px 24px 60px 24px;
  border-radius: 15px;
  background-color: #7ca1a1;
  align-items: center;
  justify-content: center; /* Added for vertical alignment */
`;
