import styled from "styled-components/native";
import { colors } from "../../../../../src/ui/theme";
import { Mlyn } from "../../../../ui/Mlyn";

const HEADER_HEIGHT = 72;
const DIVIDER_HEIGHT = 16;

export const Container = styled.View`
  flex: 1;
  background-color: ${colors.header};
  max-height: ${HEADER_HEIGHT + DIVIDER_HEIGHT}px;
`;

export const User = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Avatar = styled.Image`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  margin-left: 24px;
`;

export const UserInfo = styled.View`
  padding-top: 0px;
  margin-left: 16px;
`;

export const NameLabel = styled(Mlyn.Text)`
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 400;
  font-family: "Inter";
`;

export const Status = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const StatusIcon = Mlyn.View;

export const StatusLabel = styled(Mlyn.Text)<{ color: string }>`
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  letter-spacing: -0px;
  color: ${({ color }) => color}
`;

export const DividerImage = styled(Mlyn.Image)`
  bottom: 11px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 8px;
`;

export const PhoneButton = styled.TouchableOpacity``;

export const HamburgerButton = styled.TouchableOpacity``;

export const Hamburger = styled.Image`
  width: 35px;
  height: 35px;
`;

export const ButtonContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80px;
`;

export const Phone = styled(Mlyn.Image)`
  width: 35px;
  height: 35px;
`;

export const Background = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  /* max-height: ${HEADER_HEIGHT}px; */
  background-color: ${colors.header};
`;

export const Divider = styled.View`
  flex: 1;
  max-height: ${DIVIDER_HEIGHT}px;
  background-color: ${colors.divider};
`;
