import React, { useContext, useState } from "react";
import * as Styles from "./ActionsView.styles";
import * as FooterStyles from "../../Footer.styles";
import { useSubjectValue } from "react-mlyn";
import { useImage } from "../../../../../../ui/ImageContext";
import { Subject } from "mlyn";
import { Action, Language, Step } from "../../../../../../data/domain";
import { SoundContext } from "../../../../contexts/SoundContext";

export const ActionView = ({ children, divider = false, onPress }) => {
  return (
    <Styles.Action
      activeOpacity={0.7}
      onPress={onPress}
    >
      <FooterStyles.InputFrame>
        <Styles.Label>{children}</Styles.Label>
      </FooterStyles.InputFrame>
    </Styles.Action>
  );
};

interface Props {
  currentStep$: Subject<Step>;
  selectedLanugage$: Subject<Language>;
  sendMessage(action: Action): void;
}

export const ActionsView = ({
  currentStep$,
  sendMessage,
  selectedLanugage$,
}: Props) => {
  const { actions, id } = useSubjectValue(currentStep$);
  const { playClick } = useContext(SoundContext);
  return (
    <Styles.ActionsContainer>
      {actions.map((action, index) => (
        <ActionView
          divider={index > 0}
          key={action.id}
          onPress={() => {
            playClick();
            sendMessage(action);
          }}
        >
          {() => action.title[selectedLanugage$()]}
        </ActionView>
      ))}
    </Styles.ActionsContainer>
  );
};
