import React, { useRef } from "react";
import { useMlynEffect, useSubject, useSubjectValue } from "react-mlyn";
import { StyleSheet, View, Image } from "react-native";
import { ThemeProvider } from "styled-components/native";
// import { useFonts } from "@expo-google-fonts/inter";
// import { useFonts as useFontsRoboto } from "@expo-google-fonts/roboto";
import { useFonts } from 'expo-font';
import { Game } from "./src/app/Game";
import { light, dark } from "./src/ui/theme";
import { ImageProvider } from "./src/ui/ImageContext";
import { Subject } from "mlyn";
import "./src/utils/disableWarnings";
import "./src/boostrap";

const AppProvider = ({ settings$, children }) => {
  const theme = useSubjectValue(settings$.theme);
  return (
    <ThemeProvider theme={theme === "light" ? light : dark}>
      <SoundProvider settings$={settings$}>
        <ImageProvider theme={settings$.theme}>
          <StettingsProvider value={settings$}>{children}</StettingsProvider>
        </ImageProvider>
      </SoundProvider>
    </ThemeProvider>
  );
};

import AsyncStorage from "@react-native-async-storage/async-storage";
import { SoundProvider } from "./src/app/Game/contexts/SoundContext";
import { Settings } from "./src/data/domain";
import { StettingsProvider } from "./src/app/Game/contexts/SettingsContext";

const useSyncObject = (subject$: Subject<object>, key: string) => {
  const loaded = useRef(false);

  useMlynEffect(() => {
    const newValue = subject$();
    if (newValue && loaded.current) {
      AsyncStorage.setItem(key, JSON.stringify(newValue));
    }
  });

  console.log(">>> getItem:", key);
  AsyncStorage.getItem(key).then((initialValue) => {
    console.log(">>> initialValue:", initialValue);
    if (initialValue) {
      try {
        subject$({ ...JSON.parse(initialValue), settingsLoaded: true });
      } catch (err) {
        // @ts-ignore
        subject$.settingsLoaded(true);
        console.log("couldnt load settings");
      }
    } else {
      // @ts-ignore
      subject$.settingsLoaded(true);
    }
    loaded.current = true;
  });
};

const Root = () => {
  const settings$ = useSubject<Settings>({
    settingsLoaded: false,
    musicOn: true,
    soundOn: true,
    theme: "dark",
    language: "by",
  });
  let [fontsLoaded] = useFonts({
    Inter: require('@expo-google-fonts/inter'),
    Roboto: require('@expo-google-fonts/roboto/Roboto_400Regular.ttf'),
  });
  // useFonts({
  //   Inter: require("@expo-google-fonts/inter"),
  //   Roboto: require("@expo-google-fonts/roboto"),
  // });
  // useFontsRoboto({
  // });
  useSyncObject(settings$, "@local/settings");
  return (
    <View style={styles.container}>
      <AppProvider settings$={settings$}>
        <Game settings$={settings$} />
      </AppProvider>
    </View>
  );
};

export default function App() {
  return <Root />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});
