import React from "react";
import * as Styles from "../Footer.styles";

interface Props {
  language$: any;
}

export const ProtagonistIsWriting = (props: Props) => {
  const { language$ } = props;
  return (
    <Styles.FooterTransparent>
      <Styles.TransparentContainer>
        <Styles.InputText>{language$.martinIsWriting}</Styles.InputText>
      </Styles.TransparentContainer>
    </Styles.FooterTransparent>
  );
};
