import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import { Video, ResizeMode } from "expo-av";
import { useSubject, useSubjectValue } from "react-mlyn";
import Svg, { Path } from "react-native-svg";
import { paymentService } from "../../../../service/paymentService";

const PlayIcon = () => (
  <Svg height="50" width="50" viewBox="0 0 24 24">
    <Path d="M8 5v14l11-7z" fill="white" />
  </Svg>
);

const VideoPlayer = ({ videoUri$, posterUri$, isFirstVideo }) => {
  const videoUri = useSubjectValue(videoUri$);
  const posterUri = useSubjectValue(posterUri$);
  const videoRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaybackFinished, setIsPlaybackFinished] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);

  const handleVideoPress = async () => {
    if (!isFirstVideo() && !paymentService.isPayingUser()) {
      if (!(await paymentService.initiatePayment())) {
        return;
      }
    }
    if (isPlaybackFinished) {
      videoRef.current.playFromPositionAsync(0);
    }
    setIsPlaying(!isPlaying);
  };
  const mounted = useSubject(false);
  useEffect(() => {
    if (!mounted()) {
      mounted(true);
      return;
    }
    if (videoRef.current) {
      if (isPlaying) {
        setIsPlaybackFinished(false);
        videoRef.current.playAsync();
      } else {
        videoRef.current.pauseAsync();
      }
    }
  }, [isPlaying]);

  const onPlaybackStatusUpdate = (playbackStatus) => {
    if (playbackStatus.isBuffering) {
      setIsBuffering(true);
    } else {
      setIsBuffering(false);
    }
    if (playbackStatus.didJustFinish) {
      setIsPlaying(false);
      setIsPlaybackFinished(true);
    }
  };
  const width = Dimensions.get("window").width * 0.6;
  const height = (width / 16) * 9;
  return (
    <View style={[styles.container, { width, height }]}>
      <TouchableOpacity onPress={handleVideoPress} style={styles.video}>
        <Video
          ref={videoRef}
          style={{ width, height }}
          source={{ uri: videoUri }}
          usePoster={isPlaybackFinished}
          posterSource={{ uri: posterUri }}
          resizeMode={ResizeMode.CONTAIN}
          videoStyle={{ width, height }}
          onPlaybackStatusUpdate={onPlaybackStatusUpdate}
        />
        {isBuffering && (
          <View style={styles.buffering}>
            <ActivityIndicator size="large" color="#FFFFFF" />
          </View>
        )}
        {isPlaying ? null : (
          <View style={styles.controls}>
            <PlayIcon />
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    justifyContent: "center",
    // alignItems: "center",
  },
  controls: {
    backgroundColor: "rgba(0,0,0,0.4)",
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  buffering: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default VideoPlayer;
