import { Subject } from "mlyn";
import { useEffect } from "react";
import { useMlynEffect } from "react-mlyn";
import { Platform } from "react-native";
import { Settings } from "../../../data/domain";
import { Audio } from "expo-av";
import { Sound } from "expo-av/build/Audio";
import { BackgroundMusic } from "../../../../Assets";

let backgroundSound: Sound;

const playBackground = (settings$: Subject<Settings>) => {
  if (settings$.settingsLoaded()) {
    if (settings$.musicOn()) {
      if (backgroundSound) {
        backgroundSound.pauseAsync().then(() => {
          backgroundSound.playAsync();
        });
      }
    } else {
      if (backgroundSound) {
        backgroundSound.pauseAsync();
      }
    }
  }
};

const useLoadBackgroundSound = async (settings$: Subject<Settings>) => {
  useEffect(() => {
    (async () => {
      try {
        const res = await Audio.Sound.createAsync(
          BackgroundMusic
        );
        const { sound } = res;
        backgroundSound = sound
        backgroundSound.setIsLoopingAsync(true);
        playBackground(settings$);
      } catch (err) {
        console.log(">>> sound load err: ", err);
      }
    })();
    return () => {
      backgroundSound.stopAsync();
    }
  }, []);
};

export const useBackgroundSound = (settings$: Subject<Settings>) => {
  useLoadBackgroundSound(settings$);
  useEffect(() => {
    if (Platform.OS === "web") {
      const musicHandler = () => {
        document.removeEventListener("click", musicHandler);
        playBackground(settings$);
      };
      document.addEventListener("click", musicHandler);
      return () => document.removeEventListener("click", musicHandler);
    }
  }, []);
  useMlynEffect(() => {
    console.log(">>> play sound!!!!!!");
    playBackground(settings$);
    return () => {
      backgroundSound.pauseAsync();
    }
  });
};
