import React, { useContext, useEffect } from "react";
import { useHandler } from "react-use-handler";
import { useSubject, Show, useProjectSubject, useMlynEffect } from "react-mlyn";
import { Subject } from "mlyn";
import { Alert, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useHistory, intitialHistory } from "./hooks/useHistory";
import { useGameFlow } from "./hooks/useGameFlow";
import * as Styles from "./Game.styles";
import { Menu } from "./components/Menu";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Loader } from "./components/Loader";
import { Chat } from "./components/Chat";
import { localizations$ } from "../../ui/locale";
import { SoundContext } from "./contexts/SoundContext";
import { useBackgroundSound } from "./hooks/useBackgroundSound";
import { State } from "../../data/domain";
import { GameBackground } from "./components/GameBackground";
import { FatalStepView } from "./components/Footer/components/FatalStepView";
import { VictoryStepView } from "./components/Footer/components/VictoryStepView";

const initialState: State = {
  premiumAccount: false,
  history: intitialHistory,
  localizations: localizations$,
  notifications: false,
  loading: true,
  waitingUntil: undefined,
  menuOpened: false,
  expanded: false,
  promptExpanded: false,
  steps: [],
  variables: [],
};
let i = 0;

const fetchStepsWithCacheFalldown = async (fileName: string) => {
  const cacheKey = `steps-${fileName}`;
  try {
    const response = await fetch(
      `https://www.sot.quest/steps/${fileName || "default"}`
    );
    const result = await response.json();
    const gameConfig = JSON.stringify(result);
    // console.log(">>> gameConfig:", gameConfig);
    await AsyncStorage.setItem(cacheKey, gameConfig);
    console.log(">>> loaded game config from server");
    return result;
  } catch (err) {
    console.log(2);
    const cache = await AsyncStorage.getItem(cacheKey);
    if (cache) {
      console.log(">>> loaded game config from cache");
      return JSON.parse(cache);
    }
    throw new Error("couldn't load game config");
  }
};

const initializeGameConfig = async (
  state: Subject<State>,
  preloadHistory: any,
  fileName: string
) => {
  try {
    const { steps, variables } = await fetchStepsWithCacheFalldown(fileName);
    // console.log(">>> new steps:", steps);
    state({ ...state(), variables, steps });
    preloadHistory(state.variables, steps);
  } catch (err) {
    console.log(">>> error fetching steps: ", (err as any).message);
    Alert.alert("Памылка загрузкі", "", [
      {
        text: "Перагрузіць",
        onPress: () => {
          initializeGameConfig(state, preloadHistory, fileName);
        },
      },
    ]);
  }
};

const useInitializeGame = (state: Subject<State>) => {
  const { preloadHistory } = useHistory(state.history);
  useEffect(() => {
    (async () => {
      let fileName;
      if (window.location) {
        const urlParams = new URLSearchParams(window.location.search);
        fileName = urlParams.get("file");
      } else {
        // fileName = "selection";
        fileName = "default";
      }
      await initializeGameConfig(state, preloadHistory, fileName as string);
    })();
  }, []);
};

export const GameComponent = React.memo(({ settings$ }) => {
  const state = useSubject<State>(initialState);
  useBackgroundSound(settings$);
  useInitializeGame(state);
  const {
    revertTo,
    passStep,
    resetHandler,
    backToSafe,
    currentStep,
    skipTimeout,
    awayReason,
  } = useGameFlow(state, settings$);

  const { playClick } = useContext(SoundContext);
  const onReset = useHandler(() => {
    playClick();
    resetHandler();
  });

  const restart = useHandler(() => {
    const { history } = state;
    history([history()[0]]);
    history[0].selectedAction(undefined);
  });

  const language$ = useProjectSubject(() => {
    return localizations$[settings$.language()];
  });
  const isStepFatal = () => currentStep() && currentStep().fatal;
  const isStepVictory = () => currentStep() && currentStep().victory;
  return (
    <Styles.Container>
      <Header
        language$={language$}
        menuOpened={state.menuOpened}
        waitingUntil$={state.waitingUntil}
      />
      <GameBackground>
        <View style={{ flex: 1 }}>
          <Chat
            loading={state.loading}
            revertTo={revertTo}
            selectedLanguage$={settings$.language}
            history={state.history}
            steps={state.steps}
            language={language$}
            expanded={state.expanded}
          />
        </View>
        <Footer
          state={state}
          awayReason={awayReason}
          skipTimeout={skipTimeout}
          selectedLanugage$={settings$.language}
          language$={language$}
          loading={state.loading}
          restart={restart}
          waitingUntil={state.waitingUntil}
          backToSafe={backToSafe}
          passStep={passStep}
          currentStep={currentStep}
          expanded={state.expanded}
          promptExpanded={state.promptExpanded}
        />
        <Show when={isStepFatal}>
          {() => (
            <FatalStepView
              restart={restart}
              step$={currentStep}
              language$={language$}
              backToSafe={backToSafe}
            />
          )}
        </Show>
        <Show when={isStepVictory}>
          {() => <VictoryStepView restart={restart} language$={language$} currentStep$={currentStep}/>}
        </Show>
        <Show when={() => state.menuOpened()}>
          {() => {
            return (
              <Menu
                language$={language$}
                settings$={settings$}
                onReset={onReset}
                opened={state.menuOpened}
              />
            );
          }}
        </Show>
      </GameBackground>
    </Styles.Container>
  );
});
