import React, { useEffect, useMemo } from "react";
import { Animated } from "react-native";
import * as Styles from "./Cursor.styles";

export const Cursor = React.memo(() => {
  const animated = useMemo(() => new Animated.Value(0), []);
  useEffect(() => {
    let appering = true;
    const onComplete = ({ finished }) => {
      if (finished) {
        appering = !appering;
        Animated.timing(animated, {
          useNativeDriver: false,
          toValue: appering ? 1 : 0,
          duration: 0,
          delay: 600,
        }).start(onComplete);
      }
    };
    Animated.timing(animated, {
      useNativeDriver: false,
      toValue: 1,
      duration: 0,
      delay: 600,
    }).start(onComplete);
    return () => {
      animated.stopAnimation();
    };
  }, []);
  return <Styles.Cursor style={{ opacity: animated }}></Styles.Cursor>;
});
