import React from "react";
import { Svg, Path } from "react-native-svg";

const BellIcon = () => {
  return (
    <Svg width="31" height="30" viewBox="0 0 31 30" fill="none">
      <Path
        d="M8 10C8 8.01088 8.79018 6.10322 10.1967 4.6967C11.6032 3.29018 13.5109 2.5 15.5 2.5C17.4891 2.5 19.3968 3.29018 20.8033 4.6967C22.2098 6.10322 23 8.01088 23 10C23 18.75 26.75 21.25 26.75 21.25H4.25C4.25 21.25 8 18.75 8 10Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.375 26.25C13.5842 26.6306 13.8918 26.9479 14.2656 27.169C14.6394 27.39 15.0657 27.5067 15.5 27.5067C15.9343 27.5067 16.3606 27.39 16.7344 27.169C17.1082 26.9479 17.4158 26.6306 17.625 26.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default BellIcon;
