import { Subject, batch, createSubject } from "mlyn";
import {
  HistoryItem,
  getStep,
  historyService,
  isStepScheduled,
} from "./useHistory";
import * as Analytics from "expo-firebase-analytics";
import { Action, Language, Step } from "../../../data/domain";
import { useHandler } from "react-use-handler";
import { addMilliseconds, differenceInMilliseconds } from "date-fns";
import { throttle } from "lodash";
import { useMlynEffect, useProjectSubject, useSubject } from "react-mlyn";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Notifications from "expo-notifications";

import "firebase/compat/messaging";
import { Platform } from "react-native";
import { useEffect } from "react";
import { paymentService } from "../../service/paymentService";

interface Settings {
  musicOn: boolean;
  soundOn: boolean;
  theme: string;
  language: Language;
}
interface AppState {
  history: HistoryItem[];
  localizations: { [key: string]: { [key: string]: string } };
  notifications: boolean;
  loading: boolean;
  waitingUntil: string | undefined;
  menuOpened: boolean;
  expanded: boolean;
  promptExpanded: boolean;
  steps: any[];
  variables: any[];
}

const MARTIN_IS_AWAY_TIMEOUT = 30 * 1000;

const shouldGoToNextStep = (step: Step) => {
  return step && step.autoswitch && !step.fatal && !step.victory;
};

const randomId = () => Math.floor(Math.random() * 10e15).toString(16);

async function registerForPushNotificationsAsync() {
  if (Platform.OS === "web") {
    return;
  }
  const { status } = await Notifications.getPermissionsAsync();
  if (status !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    if (status !== "granted") {
      // alert("Failed to get push token for push notification!");
      return undefined;
    }
  }
  const token = (
    await Notifications.getExpoPushTokenAsync({
      projectId: "c76ebced-4d1b-46ed-ad18-d59b6bd4b061",
    })
  ).data;
  return token;
}

const generateNotificationConfig = async (settings$: Subject<Settings>) => {
  const pushToken = await registerForPushNotificationsAsync();
  if (!pushToken) {
    return;
  }
  const existendUserId = await AsyncStorage.getItem("userId");
  const userId = existendUserId || randomId();
  if (!existendUserId) {
    await AsyncStorage.setItem("userId", userId);
  }
  return {
    userId,
    pushToken,
    language: settings$.language(),
  };
};

const post = (endpoint: string, body: any) => {
  console.log(`POST ${endpoint}`);
  return fetch(`https://www.sot.quest${endpoint}`, {
    method: "POST", // Request method
    headers: {
      "Content-Type": "application/json", // Set content type to JSON
    },
    body: JSON.stringify(body), // Convert JavaScript object to JSON
  });
};

const sendActivityTime = throttle(async (settings$: Subject<Settings>) => {
  if (Platform.OS === "web") {
    return;
  }
  try {
    const body = await generateNotificationConfig(settings$);
    // console.log(">>> body:", body);
    return post("/update-activity-time", body);
  } catch (err) {
    console.log(">>> sendActivityTime: error submitting requiest");
  }
}, 500);

const scheduleReturnNotification = throttle(
  async (settings$: Subject<Settings>, delayMs: number) => {
    if (Platform.OS === "web") {
      return;
    }
    try {
      const body = await generateNotificationConfig(settings$);
      // console.log(">>> body:", body);
      return post("/schedule-return-notification", { ...body, delayMs });
    } catch (err) {
      console.log(">>> scheduleReturnNotification: error submitting requiest");
    }
  },
  500
);

export const useGameFlow = (
  state: Subject<AppState>,
  settings$: Subject<Settings>
) => {
  let currentTimeout: any;
  let autoSwitchTimeout: any;
  let loadingTimeout: any;

  useMlynEffect(() => {
    // console.log(">>> r", state.steps());
    if (state.steps().length > 0) {
      if (autoSwitchTimeout) {
        return;
      }
      autoSwitchTimeout = setTimeout(() => {
        clearTimeout(autoSwitchTimeout);
        autoSwitchTimeout = 0;
        state.loading(false);
        if (state.history().length === 0) {
          state.history([
            {
              stepId: state.steps()[0].id,
              selectedAction: undefined,
              meta: undefined,
            },
          ]);
        } else {
          const lastHistoryItem = state.history()[state.history().length - 1];
          const lastStep = state
            .steps()
            .find(({ id }) => id === lastHistoryItem.stepId);
          if (shouldGoToNextStep(lastStep)) {
            goToStep(lastStep.autoNextStep);
          }
        }
      }, 1000);
    }
  });

  const resetAllTimers = () => {
    clearTimeout(currentTimeout);
    clearTimeout(autoSwitchTimeout);
    clearTimeout(loadingTimeout);
  };

  const scheduledStepId = useSubject("");

  const onTimeoutFinished = () => {
    const { history, variables, steps } = state;
    const nextStep = getStep(variables(), steps(), scheduledStepId());
    historyService.saveSheduled(null);
    state.waitingUntil("");
    clearTimeout(loadingTimeout);
    state.loading(false);
    Analytics.logEvent('game_progress', {
      step_id: nextStep.id,
      step_number: state.history().length + 1,
    });
    state.history([
      ...history(),
      {
        stepId: nextStep.id,
        selectedAction: undefined,
        meta: undefined,
      },
    ]);
    if (shouldGoToNextStep(nextStep)) {
      goToStep(nextStep.autoNextStep);
    }
  };

  const goToStep = useHandler((stepOrVariableId: string) => {
    const { history, variables, steps } = state;
    const nextStep = getStep(variables(), steps(), stepOrVariableId);
    const stepTimeout = nextStep.timeout
      ? nextStep.timeout
      : Math.max(
          Math.min(nextStep.title[settings$.language()].length * 70, 4500),
          100
        );

    if (stepTimeout > 300) {
      clearTimeout(loadingTimeout);
      loadingTimeout = setTimeout(() => {
        state.loading(true);
      }, 300);
    }

    // const MARTIN_IS_AWAY = 1000;
    if (stepTimeout > MARTIN_IS_AWAY_TIMEOUT) {
      scheduleReturnNotification(settings$, stepTimeout);
      const timeout = addMilliseconds(new Date(), stepTimeout).toISOString();
      historyService.saveSheduled({ timeout, stepId: stepOrVariableId });
      state.waitingUntil(timeout);
    } else {
      historyService.saveSheduled(null);
    }
    clearTimeout(currentTimeout);
    scheduledStepId(stepOrVariableId);
    currentTimeout = setTimeout(onTimeoutFinished, stepTimeout);
  });

  const revertTo = useHandler((stepId: string) => {
    const { history, waitingUntil, loading } = state;
    if (waitingUntil() || loading()) {
      return;
    }
    resetAllTimers();
    batch(() => {
      const stepIndex = history().findIndex((item) => item.stepId === stepId);
      history(history().slice(0, stepIndex + 1));
      history[history().length - 1].selectedAction(undefined);
      state.promptExpanded(false);
    });
  });

  const passStep = useHandler((action: Action, meta: any) => {
    const { history, variables, steps } = state;
    const lastHistoryItem = history[history().length - 1];
    if (meta) {
      lastHistoryItem.meta(meta);
    }
    lastHistoryItem.selectedAction(action.id);
    for (const assignment of action.assignments) {
      const variableIndex = variables().findIndex(
        ({ id }) => id === assignment.variableId
      );
      variables[variableIndex].stepId(assignment.stepId);
    }
    const nextStep = getStep(variables(), steps(), action.nextId);
    if (nextStep.timeout < MARTIN_IS_AWAY_TIMEOUT) {
      sendActivityTime(settings$);
    }
    goToStep(action.nextId);
  });

  const resetHandler = () => {
    resetAllTimers();
    state({
      ...state(),
      waitingUntil: undefined,
      menuOpened: false,
      expanded: false,
      promptExpanded: false,
      loading: false,
      history: [
        {
          stepId: state.steps()[0].id,
          selectedAction: undefined,
          meta: undefined,
        },
      ],
    });
  };

  const awayReason = useSubject("");
  useMlynEffect(() => {
    const { variables, steps } = state;
    const ns = getStep(variables(), steps(), scheduledStepId());
    if (ns) {
      const index = state.steps().findIndex((step) => step.id === ns.id);
      const s = state.steps[index]();
      if (s.awayReason) {
        awayReason(s.awayReason[settings$.language()]);
      }
    }
  });

  const currentStep = useProjectSubject(() => {
    const history = state.history();
    if (history.length === 0) {
      return createSubject(false);
    }
    const index = state
      .steps()
      .findIndex((step) => step.id === history[history.length - 1].stepId);
    return state.steps[index];
  });

  const backToSafe = useHandler(() => {
    const { history } = state;
    const lastIndex = history().length - 1;
    batch(() => {
      for (let i = lastIndex; i >= 0; i--) {
        if (history()[i].stepId === currentStep.autoNextStep()) {
          history(history().slice(0, i + 1));
          history[i].selectedAction(undefined);
          break;
        }
      }
      state({
        ...state(),
        waitingUntil: undefined,
        menuOpened: false,
        expanded: false,
        promptExpanded: false,
        loading: false,
      });
    });
  });

  const skipTimeout = async () => {
    if (paymentService.isPayingUser()) {
      onTimeoutFinished();
    } else {
      if (await paymentService.initiatePayment()) {
        onTimeoutFinished();
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (await isStepScheduled()) {
        const sheduled = await historyService.getSheduled();
        state.waitingUntil(sheduled.timeout);
        scheduledStepId(sheduled.stepId);
        const millisecondsToFutureDate = differenceInMilliseconds(
          new Date(sheduled.timeout),
          new Date()
        );
        currentTimeout = setTimeout(
          onTimeoutFinished,
          millisecondsToFutureDate
        );
      }
    })();
  }, []);

  return {
    awayReason,
    skipTimeout,
    currentStep,
    backToSafe,
    revertTo,
    passStep,
    resetHandler,
  };
};
