import React from 'react';
import Svg, { Path } from 'react-native-svg';

const SubmitIcon = () => (
  <Svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M24 10.5359C26.6667 12.0755 26.6667 15.9245 24 17.4641L6.75 27.4234C4.08333 28.963 0.749999 27.0385 0.749999 23.9593L0.75 4.04071C0.75 0.961507 4.08333 -0.962996 6.75 0.576605L24 10.5359Z" fill="#525252"/>
  </Svg>
);

export default SubmitIcon;
