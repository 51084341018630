import React from "react";
import { Svg, Path } from "react-native-svg";

const Phone = () => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M18.9996 13.4817V15.5893C19.0004 15.7849 18.9602 15.9786 18.8817 16.1578C18.8031 16.3371 18.6879 16.498 18.5435 16.6303C18.399 16.7626 18.2285 16.8633 18.0428 16.9259C17.8571 16.9886 17.6603 17.0119 17.465 16.9943C15.299 16.7594 13.2183 16.0207 11.3902 14.8376C9.68945 13.7589 8.24748 12.3198 7.16674 10.6224C5.9771 8.78969 5.23677 6.70306 5.00571 4.53156C4.98812 4.33729 5.01126 4.1415 5.07364 3.95664C5.13603 3.77178 5.2363 3.60191 5.36807 3.45785C5.49984 3.31378 5.66022 3.19868 5.839 3.11986C6.01779 3.04105 6.21106 3.00025 6.40651 3.00007H8.51826C8.85987 2.99671 9.19105 3.11744 9.45007 3.33976C9.70909 3.56208 9.87827 3.87081 9.92609 4.20841C10.0152 4.88287 10.1805 5.54511 10.4188 6.18249C10.5135 6.43394 10.5340 6.70721 10.4779 6.96993C10.4218 7.23265 10.2913 7.4738 10.1021 7.66481L9.20809 8.55701C10.2102 10.3158 11.6693 11.7721 13.4316 12.7721L14.3256 11.8799C14.5169 11.6911 14.7586 11.5609 15.0218 11.5049C15.2851 11.4488 15.5589 11.4693 15.8108 11.5638C16.4495 11.8016 17.113 11.9666 17.7888 12.0556C18.1308 12.1037 18.4431 12.2756 18.6663 12.5386C18.8895 12.8015 19.0081 13.1372 18.9996 13.4817Z"
        fill="white" // Changed from white to black for visibility without filters
        stroke="white" // Outline color can be adjusted or removed as per design requirements
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Phone;
