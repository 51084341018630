import styled, { css } from "styled-components/native";
import { userSelect } from "../../../../ui/common.styles";
import { Mlyn } from "../../../../ui/Mlyn";
import { colors } from "../../../../ui/theme";
import { MessageCss } from "./components/ActionsView/ActionsView.styles";

export const Container = styled.View`
  width: 100%;
  min-height: 44px;
`;

export const InputText = styled(Mlyn.Text)`
  font-size: 21;
  margin-bottom: 2px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: white;
  ${userSelect};
`;

export const InfoContainer = styled.View`
  background-color: ${colors.header};
`;

export const Answer = styled.View`
  display: flex;
  padding: 14px;
  overflow: visible;
  flex-direction: row;
`;

export const Info = styled.View`
  background-color: ${colors.header};
  flex-direction: row;
  justify-content: space-between;
  ${MessageCss}
`;

export const Label = styled(Mlyn.Text)`
  /* text-align: center; */
  margin-right: 4px;
  color: white
  font-size: 16;
`;

export const LabelAway = styled(Label)`
  text-align: center;
  flex: 1;
`;

export const FooterButton = styled.TouchableOpacity`
  ${userSelect};
  flex-direction: row;
  height: 76px;
  padding: 12px;
`;

export const Submit = styled.View`
  align-items: center;
  justify-content: space-around;
  padding-left: 5px;
  margin-left: 15px;
  width: 56px;
  height: 56px;
  border-radius: 30px;
  background-color: #3e3e3e;
`;

export const PropmptInput = styled(Mlyn.TextInput)`
  color: white;
  height: 40px;
  width: 100%;
  font-size: 24px;
`;

export const InputFrame = styled.View`
  align-items: center;
  flex-direction: row;
  min-height: 52px;
  flex: 1;
  width: 100%;
  padding-left: 20px;
  border-radius: 24px;
  background-color: #3e3e3e;
`;

export const Footer = styled.View`
  bottom: 0px;
  width: 100%;
  background-color: ${colors.header};
`;

export const Background = styled.View`
  background-color: ${colors.header};
  align-items: center;
  flex-direction: row;
  min-height: 76px;
  padding: 12px;
  flex: 1;
`;

export const TransparentContainer = styled.View`
  align-items: center;
  flex-direction: row;
  min-height: 52px;
  padding: 12px;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.3);
`;
export const FooterTransparent = styled.View`
  width: 100%;
`;
