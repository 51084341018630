import React, { useContext } from "react";
import { Show, seal } from "react-mlyn";
import { Alert } from "react-native";
import * as Styles from "./Header.styles";
import { useImage$ } from "../../../../ui/ImageContext";
import { colors, useColor, useColorValue } from "../../../../ui/theme";
import { SoundContext } from "../../contexts/SoundContext";
import Phone from "../../../../ui/icons/Phone";
import Hamburger from "../../../../ui/icons/Hamburger";
import CrossIcon from "../../../../ui/icons/Cross";

export const HeaderComponent = seal(
  ({ language$, menuOpened, waitingUntil$ }) => {
    const { playClick } = useContext(SoundContext);
    const networkStatus$ = () => {
      return waitingUntil$() ? language$.offline() : language$.online();
    };
    const connectionColor = useColorValue(() => {
      return !waitingUntil$() ? colors.connect : colors.noConnection;
    });
    return (
      <Styles.Container>
        <Styles.Background>
          <Styles.User>
            <Styles.Avatar
              source={require("../../../../../assets/martin.jpeg")}
            />
            <Styles.UserInfo>
              <Styles.NameLabel>{language$.userName}</Styles.NameLabel>
              <Styles.Status>
                <Styles.StatusLabel color={connectionColor}>
                  {networkStatus$}
                </Styles.StatusLabel>
              </Styles.Status>
            </Styles.UserInfo>
          </Styles.User>
          <Styles.ButtonContainer>
            <Styles.PhoneButton
              onPress={() => {
                playClick();
                Alert.alert(language$.noConnection());
              }}
            >
              <Phone />
            </Styles.PhoneButton>
            <Styles.HamburgerButton
              onPress={() => {
                playClick();
                menuOpened(!menuOpened());
              }}
            >
              <Show when={menuOpened}>{() => <CrossIcon />}</Show>
              <Show when={() => !menuOpened()}>{() => <Hamburger />}</Show>
            </Styles.HamburgerButton>
          </Styles.ButtonContainer>
        </Styles.Background>
      </Styles.Container>
    );
  }
);
