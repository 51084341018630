import { createSubject } from "mlyn";

const isPayingUser = createSubject(true);

export const paymentService = {
  initiatePayment: () => {
    return Promise.resolve(true);
  },
  refresh: () => {},
  isPayingUser,
};
