import React, { useContext } from "react";
import { TouchableOpacity } from "react-native";
import { SoundContext } from "../../app/Game/contexts/SoundContext";

const IconButton = ({ children, onPress }) => {
  const { playClick } = useContext(SoundContext);
  const handlePress = () => {
    playClick();
    onPress();
  };
  return (
    <TouchableOpacity
      style={{ flexDirection: "row", alignItems: "center" }}
      onPress={handlePress}
    >
      {children}
    </TouchableOpacity>
  );
};

export default IconButton;
