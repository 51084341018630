import React from "react";
import Svg, { Path } from "react-native-svg";

const CrossIcon = () => {
  return (
    <Svg
      style={{ marginRight: 10, marginTop: 10 }}
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
    >
      <Path
        d="M5 1L23 16.5M5 16.5L23 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default CrossIcon;
