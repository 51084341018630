import React, { useMemo, useState } from "react";
import {
  ImageProps,
  Image,
  ViewProps,
  View,
  Text,
  TextInput,
  TextProps,
} from "react-native";
import { mlynify } from "react-mlyn";

export const partitionObjectDeep = (
  entries: any,
) =>
  Object.keys(entries).reduce(
    (result, key) => {
      const lastIndex = key.length - 1;
      if (key.indexOf("$") === lastIndex) {
        result[1][key.substr(0, lastIndex)] = entries[key];
      } else {
        result[0][key] = entries[key];
      }
      return result;
    },
    [{} as any, {} as any]
  );


const getValues = (
  subjects: { [key: string]: () => any },
) => {
  const newValues: any = {};
  for (let key in subjects) {
    newValues[key] = getValues(subjects[key] as Subject<any>, []);
  }

  return newValues;
};

export const Mlyn = {
  Image: mlynify((props: ImageProps) => <Image {...props} />),
  View: mlynify((props: ViewProps) => <View {...props} />),
  Text: mlynify((props: TextProps) => <Text {...props} />),
  TextInput: mlynify((props: TextProps) => <TextInput {...props} />),
};
