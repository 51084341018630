import React from "react";
import { Svg, Path } from "react-native-svg";

const Sound = () => {
  return (
    <Svg width="31" height="30" viewBox="0 0 31 30" fill="none">
      <Path
        d="M14.25 6.25L8 11.25H3V18.75H8L14.25 23.75V6.25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.925 10.5752C21.0967 11.7472 21.755 13.3367 21.755 14.9939C21.755 16.6512 21.0967 18.2406 19.925 19.4127"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.3374 6.16211C26.6808 8.50621 27.9972 11.6851 27.9972 14.9996C27.9972 18.3142 26.6808 21.493 24.3374 23.8371"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Sound;
