import React from "react";
import * as Styles from "../Footer.styles";
import { useSubject } from "react-mlyn";
import { View, TouchableOpacity } from "react-native";
import SubmitIcon from "../../../../../ui/icons/SubmitIcon";
import { Cursor } from "./cursor";
import { Mlyn } from "../../../../../ui/Mlyn";

interface Props {
  language$: any;
  answerQuestion(value: string): void;
}

export const PropmtView = (props: Props) => {
  const { language$, answerQuestion } = props;
  const answer$ = useSubject("");
  return (
    <Styles.Footer>
      <Styles.Background>
        <Styles.InputFrame>
          <View style={{ position: "absolute" }}>
            <Cursor />
          </View>
          <Styles.PropmptInput value$={answer$} onChange={(e) => answer$(e.target.value)} />
        </Styles.InputFrame>
        <TouchableOpacity
          onPress={() => {
            if (answer$()) {
              answerQuestion(answer$());
            }
          }}
          activeOpacity={0.7}
        >
          <Mlyn.View style$={() => ({ opacity: !!answer$() ? 1 : 0.5})}>
            <Styles.Submit>
              <SubmitIcon />
            </Styles.Submit>
          </Mlyn.View>
        </TouchableOpacity>
      </Styles.Background>
    </Styles.Footer>
  );
};
